import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import HeroContainer from "../components/heroContainer"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import { RoleCard } from "../components/card/MainCard"
import ButtonLink from "../components/ButtonLink"
import { AiOutlineArrowDown } from "react-icons/ai"
import PageTitle from "../components/PageTitle"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import StandardContainer from "../components/containers/StandardContainer"

const Opportunities = ({ data }) => {
  const { sanityOpportunitiesPage } = data
  const defaultHeroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBanner.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      sanityOpportunitiesPage?._rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  const containerTwoCards = [
    {
      button: {
        text: "Read More",
        className: "text-black bg-tertiary",
      },
      container: {
        className: "text-black",
      },
      divider: {
        className: "bg-tertiary",
      },
      header: {
        text: "Welcome",
        hide: false,
      },
      text: [
        'When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family. We invite you to explore our website and find out all that Sioux Lookout and area has to offer you! When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If"',
      ],
    },
    {
      button: {
        text: "Read More",
        className: "text-black bg-tertiary",
      },
      container: {
        className: "text-black",
      },
      divider: {
        className: "bg-tertiary",
      },
      header: {
        text: "Welcome",
        hide: true,
      },
      text: [
        'When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family. We invite you to explore our website and find out all that Sioux Lookout and area has to offer you! When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If"',
      ],
    },
  ]
  const containerThreeCards = [
    {
      button: {
        text: "Read More",
        className: "text-black bg-tertiary",
      },
      container: {
        className: "text-black",
      },
      divider: {
        className: "bg-tertiary",
      },
      header: {
        text: "Welcome",
        hide: false,
      },
      text: [
        'When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family. We invite you to explore our website and find out all that Sioux Lookout and area has to offer you! When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If"',
      ],
    },
  ]
  const heroProps = {
    fluid: fluidBanner,

    gradient: true,
  }

  return (
    <Layout>
      <SEO title="Opportunities" />
      <HeroContainer props={heroProps} />
      <StandardContainer className="py-10 px-3 relative text-primary">
        <div className="grid  justify-items-end">
          <ButtonLink
            to="/opportunities/#open"
            className="flex items-center gap-x-2"
          >
            Open Roles
            <AiOutlineArrowDown />
          </ButtonLink>
        </div>
        <PageTitle text="Opportunities" />
        <div className="">
          <BlockContentPrimary
            blockData={sanityOpportunitiesPage._rawRichTextContent}
          />
          <div id="open" className="pt-3">
            <PageTitle text="Open Roles" />
          </div>
          {data.jobs.edges.map(({ node: job }) => {
            const roleProps = {
              title: job.jobTitle,
              subTitle: job.subTitle,
              summary: job.summary,
              handle: job.slug.current,
              deadline: job.applicationDeadline,
            }

            return <RoleCard {...roleProps} />
          })}
        </div>
      </StandardContainer>
    </Layout>
  )
}
export default Opportunities

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "opportunity_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanityOpportunitiesPage {
      _rawRichTextContent(resolveReferences: { maxDepth: 10 })
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
    }
    jobs: allSanityJobOpening {
      edges {
        node {
          jobTitle
          subTitle
          slug {
            current
          }
          details: _rawDetails(resolveReferences: { maxDepth: 10 })
          summary
          applicationDeadline
        }
      }
    }
  }
`
